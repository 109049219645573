module.exports ={
  "propertyService": "https://eda4ajse35.execute-api.us-east-1.amazonaws.com/hotfix2",
  "domusoApiService": "https://sng1scofpj.execute-api.us-east-1.amazonaws.com/hotfix2",
  "appService": "https://domusograilsweb-hotfix2.devmuso.com",
  "reservationService": "https://6jde9aad5k.execute-api.us-east-1.amazonaws.com/hotfix2",
  "userPoolId": "us-east-1_tuVPUHTxf",
  "authService": "https://9h1138hdyh.execute-api.us-east-1.amazonaws.com/hotfix2",
  "userPoolWebClientId": "7j131uo6qmknsnblj9pr1o04it",
  "emailLinkBase": "https://domusograilsweb-hotfix2.devmuso.com",
  "payWithUrl": "https://paywith.devmuso.com",
  "supportEmail": "support-test@devmuso.com",
  "residentWebUI": "https://resident-hotfix2.devmuso.com",
  "maintenanceUrl": "https://9h1138hdyh.execute-api.us-east-1.amazonaws.com/hotfix2/maintenance",
  "env": "hotfix2"
}